import "./AboutUs.css";
import photo from '../../assets/images/0009.jpeg';

function AboutUs() {
    return (
        <section className="AboutUs">
            <div>
                <img src={photo} alt="Foto de construccion" />
            </div>
            <div>
                <h2>Sobre Nosotros</h2>
                <p>Somos una empresa de construccion dedicada materializar tus proyectos comerciales, estamos aqui para construir contigo.</p>
                <div>
                    <button>Saber Más..</button>
                </div>
            </div>
        </section>
    );
}

export default AboutUs;