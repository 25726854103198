import './Header.css';
import icon from '../../assets/logo_no_bk.png'

function Header() {
    return (
        <header className='header'>
            <div>
                <img src={icon} alt="Edifico en la noche" />
            </div>
            <div>
                <h2>Juntos Construimos Un Futuro Mejor</h2>
            </div>
        </header>
    );
}

export default Header;