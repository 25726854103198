import './Footer.css';
import logo from '../../assets/Logo.png';

function Footer() {
  return (
    <footer className='footer'>
        <div>
            <img src={logo} alt="Logo de L.I Constructor S.A.S" />
        </div>
        <div>
            <div>
                <h3>CONTACTA CON NOSOTROS</h3>
                <div>
                    <div>
                        <p>Instagram</p>
                        <p>@liconstructorsas</p>
                    </div>
                    <div>
                        <p>Twitter</p>
                        <p>@liconstructorsas</p>
                    </div>
                    <div>
                        <p>Facebook</p>
                        <p>liconstructorsas</p>
                    </div>
                </div>
            </div>
            <div>
                <h3>UBICACION</h3>
                <div>
                    <p>Bogotá</p>
                    <p>Dg 60 b sur #32-16</p>
                    <p>Colombia</p>
                </div>
            </div>
        </div>
        <div>
            <h2><a href="mailto:liconstructorsas0@gmail.com">liconstructorsas0@gmail.com</a></h2>
        </div>
    </footer>
  );
}

export default Footer;
