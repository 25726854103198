import { useState } from 'react';
import './ContactMe.css';
import { useFormspark } from "@formspark/use-formspark";

const FORMSPARK_FORM_ID = "2rG5gtZ46";

function ContactMe() {
    const [submit, submitting] = useFormspark({
        formId: FORMSPARK_FORM_ID,
    });
    
    const [nombre, setNombre] = useState("");
    const [apellido, setApellido] = useState("");
    const [numero, setNumero] = useState("");
    const [correo, setCorreo] = useState("");

    const clearData = () => {
        alert("Enviado")
        setNombre("")
        setApellido("")
        setNumero("")
        setCorreo("")
        console.log(submitting)
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        const message = {
            nombre,
            apellido,
            celular: numero,
            correo,
        }
        await submit(message ).then(clearData());
    };

    return (
        <div className='contactanos'>
            <div>
                <h4>Contáctanos</h4>
            </div>
            <div>
                <div></div>
                <div>
                    <form onSubmit={onSubmit}>
                        <div>
                            <input value={nombre} type="text" name="nombre" id="nombre" placeholder='Mario' onChange={e => setNombre(e.target.value)}/>
                            <input value={apellido} type="text" name="apellido" id="apellido" placeholder='Medina' onChange={e => setApellido(e.target.value)}/>
                        </div>
                        <div>
                            <input value={numero} type="number" name='numero' id='numero' placeholder='+57 0000000000' onChange={e => setNumero(e.target.value)}/>
                        </div>
                        <div>
                            <input value={correo} type="email" name="correo" id="correo" placeholder='Example@gmail.com' onChange={e => setCorreo(e.target.value)}/>
                        </div>
                        <div>
                            <button type="submit">Enviar</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default ContactMe;