import { useState } from "react";
import "./OurProjects.css";
import sala from '../../assets/images/0022.jpeg';
import habitacion from '../../assets/images/0052.jpeg';

const images = [
    {
        src: sala,
        title: 'Sala'
    },
    {
        src: habitacion,
        title: 'Habitacion'
    },
    {
        src: sala,
        title: 'Sala'
    }
];

function OurProjects() {
    const [currentSlide, setCurrentSlide] = useState(0);
    const itemsPerPage = 3;

    const nextSlide = () => {
        setCurrentSlide((currentSlide + itemsPerPage) % images.length);
    };
    
    const prevSlide = () => {
        setCurrentSlide((currentSlide - itemsPerPage + images.length) % images.length);
    };

    return (
        <section className="OurProjects">
            <h2>Nuestros Proyectos</h2>
            <div className="carousel">
                <button className="carousel-control prev" onClick={prevSlide}>&#10094;</button>
                <div className="carousel-inner" style={{ transform: `translateX(-${currentSlide * (100 / itemsPerPage)}%)` }}>
                    {images.map((image, index) => (
                        <div className="carousel-item" key={index}>
                            <img src={image.src} alt={image.title} />
                            <div className="carousel-caption">{image.title}</div>
                        </div>
                    ))}
                </div>
                <button className="carousel-control next" onClick={nextSlide}>&#10095;</button>
            </div>
        </section>
    );
}

export default OurProjects;
