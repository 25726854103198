import "./MisionVision.css";

function MisionVision() {
    return (
        <section className="MisionAndVision">
            <div>
                <div>
                    <h4>Misión</h4>
                    <p>Nuestra empresa ha sido creada para brindar a nuestros clientes un servicio profesional y confiable en las areas residencial, comercial y de oficina como en obras civiles.</p>
                    <p>Atender las necesidades especificas de cada proyectó; diseñar y contruir productos funcionales de ultima tendencia y excelente calidad que mejoran e impactan de manera positiva en el entorno de vida de las persona.</p>
                </div>
            </div>
            <div>
                <div>
                    <h4>Visión</h4>
                    <p>Empresa simbolo de seguridad y confianza para nuestros clientes.</p>
                    <p>Constribuir a mejorar la calidad de vida de nuestros clientes mediante nuestros servicios.</p>
                    <p>Ser reconocido por nuestros clientes , proveedores, empleados, competidores y públicos.</p>
                    <p>En general como una empresa consolidad y comprometida.</p>
                </div>
            </div>
        </section>
    );
}

export default MisionVision;