import "./OurServices.css";

function OurServices() {
    return (
        <section className="OurServices">
            <div>
                <h2>Nuestros Servicios</h2>
                <p>Estos son algunos de nuestros servicios personalizados y exclusivos en cada hora</p>
            </div>
            <div>
                <ul>
                    <li>
                        Carpitenria de madera y mecanica.
                    </li>
                    <li>
                        Plomeria.
                    </li>
                    <li>
                        Estructura.
                    </li>
                    <li>
                        Planos y licencia.
                    </li>
                    <li>
                        Estudio de suelo.
                    </li>
                    <li>
                        Licencia de construccion.
                    </li>
                </ul>
                <p><a href="/">Ver mas</a></p>
            </div>
        </section>
    );
}

export default OurServices;