import AboutUs from '../components/AboutUs/AboutUs';
import ContactMe from '../components/ContactMe/ContactMe';
import Footer from '../components/Footer/Footer';
import Header from '../components/Header/Header';
import MisionVision from '../components/MisionVision/MisionVision';
import OurProjects from '../components/OurProjects/OurProjects';
import OurServices from '../components/OurServices/OurServices';
import './Home.css';

function Home() {
  return (
    <div className='home'>
      <Header/>
      <OurServices/>
      <OurProjects/>
      <AboutUs/>
      <MisionVision/>
      <ContactMe/>
      <Footer/>
    </div>
  );
}

export default Home;
